import TextArea from '../components/TextArea';
import ShowDate from '../components/ShowDate';

export default function Vote() {
    return (
        <TextArea>
            <h1 className="text-4xl font-bold mb-6 text-[#fff444]" style={{ fontFamily: 'Belanosima', fontWeight: '400' }}>The Vote for the YAGA Winners is Now Closed</h1>
            <p className="text-2xl text-gray-100 mb-2">Thank you for your votes!</p>
            <br></br>
            <ShowDate />
        </TextArea>
    );
  }
