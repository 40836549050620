export default function ShowDate() {
    return (
        <p className="text-2xl text-gray-100 mb-2">
        We are excited to announce that The 2024 Yet Another Game Awards Show will premire <a style={{ textDecoration: 'underline', fontFamily: 'Belanosima', fontWeight: '400' }}>Thursday, February 20th 2025 at 4:00PM PST.</a>
        <br></br>
        <br></br>
        To Watch Live tune in to BlondePizza's Twitch Channel at
        <br></br>
        <a href="https://twitch.tv/blondepizza" target="_blank" rel="noopener noreferrer">twitch.tv/blondepizza</a> | <a href="https://blonde.pizza" target="_blank" rel="noopener noreferrer">blonde.pizza</a>
        </p>
    );
}