export default function NomineeDisplay() {
  const nominees = {
    bestSoloDev: [
    'LocalThunk (Balatro)',
    'LWMedia (Nightmare Kart)',
    'lamedeveloper (Webfishing)',
    'Bureau 81 (The Operator)',
    'Moonless Formless (Withering Rooms)',
    'Molegato (Frogun Encore)',
    'Tomas Sala (Bulwark Evolution: Falconeer Chronicles)',
    'Billy Basso (Animal Well)',
    'Walter Machado (Bloodsaint, Quickerflak 2, & Bullethell)',
    'Daniel Linssen (Leap Year)',
    'Parachor (Reliquia Park)',
    'FLEB (20 Small Mazes)',
    'YORUROJI (CLONY)',
    'Blink Hydrate (Push the Cat With WASD)',
    'René Rother (Children of the Sun)',
    'Suspicious Developments (Tatical Breach Wizards)'],
    bestSmallTeam: [
    'à la mode games (Sorry We\'re Closed)',
    'Talha and Jack Co. (Judero)',
    'Wrong Organ (Mouthwashing)',
    'Svavelstickan (Star Fetchers: Escape From Pork Belly)',
    'Ánimo Games Studio (Star Stuff)',
    'Miriam and Lage (Stig)',
    'Mango Protocol (CLeM)',
    'Calligram Studio (Phoenix Springs)',
    'Boomo (Slider)',
    'SFB Games (Crow Country)',
    'Odd-Meter (Indika)',
    'Aggro Crab (Another Crab\'s Treasure)',
    'Mossmouth (UFO 50)',
    'Ironwood Studios (Pacific Drive)',
    'Night Signal Entertainment (Home Safety Hotline)',
    'Strange Scaffold (I Am Your Beast)',],
    bestNewStudio: [
    'Slow Bros (Harold Halibut) ',
    'Sunset Visitor (1000xResist)',
    'Balloon Studios (Botany Manor)',
    'Pocketpair (Palworld)',
    'Day 4 Night Studios (TBA)',
    'Calligram Studio (Phoenix Springs)',],
    bestEarlyAccess: [
    'Real Web Legends: Carter\'s Quest',
    'Fields of Mistria ',
    'Enshrouded',
    'Hades 2',
    'Selaco',],
    bestSelfPublished: [
    'Fields of Mistria',
    'Webfishing',
    'Nightmare Kart',
    'Judero',
    'Harold Halibut',
    '20 Small Mazes',
    'Reliquia Park',
    'UFO 50',
    'Another Crab\'s Treasure',
    'Home Safety Hotline',
    'Tactical Breach Wizards',],
    bestGameplay: [
    'Indiana Jones & The Great Circle',
    'Cryptmaster',
    'Sorry We\'re Closed',
    'Perennial Order',
    'Leap Year',
    'Push the Cat With WASD',
    'Slider',
    'Tormenture',
    'Mullet MadJack',
    'Tactical Breach Wizards',
    'I Am Your Beast',
    'The Operator',
    'Helldivers 2',
    'Withering Rooms',
    'Sonic X Shadow Generations',
    'Star Fetchers: Escape From Pork Belly',],
    bestNarrative: [
    'Loco Motive',
    'Mouthwashing',
    '1000xResist',
    'Metaphor: ReFantazio',
    'Judero',
    'Still Wakes The Deep',],
    bestArtDirection: [
    'Botany Manor',
    'Harold Halibut',
    'Judero',
    'Sorry We\'re Closed',
    'Metaphor: ReFantazio',
    'Mullet MadJack',
    'Hades 2',
    'Phoenix Springs',
    'Paper Trail',
    'Star Fetchers: Escape From Pork Belly',
    'Arctic Eggs',
    'Nightmare Kart',],
    bestOriginalScore: [
    'Judero by: Talha Kaya, Jack King-Spooner & Beth Sbresni',
    'Harold Halibut by: Onat Hekimoğlu & Holger Schnausen',
    'Star Fetchers: Escape From Pork Belly by: Saffron',
    'Mullet MadJack by: Fernando Pepe and Mateus Polati',
    'Hades 2 by: Darren Korb',
    'Phoenix Springs by: Alexander Smith',
    'Star Stuff by: Ánimo Games Studio',
    'Gambit Shifter by: Loris Spanu',
    'Withering Rooms by: Moonless Formless',],
    bestSong: [
    'Polluted Platoon Pathfinder (Another Crab\'s Treasure)',
    'Exhaust (Star Fetchers: Escape from Pork Belly)',
    'Leave a Note (Botany Manor)',
    'Pantry (Cryptmaster)',
    'Tam Lynn (Judero)',
    'Kongregate (Star Fetchers: Escape from Pork Belly)',
    'Church of Burdens NG (Withering Rooms) ',
    'Without You (Sonic X Shadow Generations)',
    'Jack the Mullet Mad (Mullet MadJack)',],
    bestAudioDesign: [
    'Star Fetchers: Escape From Pork Belly',
    'Gambit Shifter',
    'Star Stuff',
    'Harold Halibut',
    'Mouthwashing',
    'Pacific Drive',],
    bestWorldBuilding: [
    'Harold Halibut',
    'Cryptmaster ',
    '1000xResist ',
    'Judero',
    'Metaphor: ReFantazio',
    'Slider',
    'CLeM',
    'Reliquia Park',
    'Still Wakes The Deep',
    'Mouthwashing',],
    bestCameraWork: [
    'Harold Halibut',
    'Mouthwashing',
    'Silent Hill 2',
    'Real Web Legends: Carter\'s Quest',
    'Sorry We\'re Closed',
    '1000xResist ',],
    bestPerformance: [
    'Jack King-Spooner - Judero & Additional (Judero)',
    'Pat Garrett - Jeanne Mareaux (Harold Halibut)',
    'Angel Haven Rey - Paige (Paper Trail)',
    'Alex Anderson Crow - Iris (Phoenix Springs)',
    'Stewart Clarke - Leon Strohl da Haliaetus (Metaphor: ReFantazio)',],
    mostImpactful: [
    '1000xResist',
    'Paper Trail',
    'Mouthwashing',
    'Judero',
    'Indika',],
    mostInnovative: [
    'Bulwark Evolution: Falconeer Chronicles',
    'Cryptmaster',
    'Threshold',
    'Harold Halibut',
    'Leap Year',
    'Slider',
    'Push the Cat With WASD',
    'Helldivers 2',
    'Tactical Breach Wizards',
    'Animal Well',
    'Reliquia Park',],
    bestOngoing: [
    'Stardew Valley',
    'Helldivers 2',
    'Exanima',
    'DayZ',
    'No Man\'s Sky',
    'The Binding of Isaac',
    'Control',
    'Honkai Star Rail',
    'Final Fantasy XIV',],
    bestCommunitySupport: [
    'Dwarf Fortress',
    'Final Fantasy XIV',
    'Cult of the Lamb',
    'Stardew Valley',
    'No Man\'s Sky',
    'Baldur\'s Gate III',],
    bestItchioGame: [
    'Rogue-Like Deck Builder',
    'These Heavenly Bodies',
    'This is Not Your House',
    'Nightmare Kart',
    'Reliquia Park',
    'Voices of the Void',],
    bestAction: [
    'Another Crab\'s Treasure',
    'Arco',
    'Warhammer 40,000: Space Marine 2',
    'I Am Your Beast',
    'Star Wars Outlaws',
    'Mullet MadJack',],
    bestAdventure: [
    'Thank Goodness You\'re Here!',
    'Indiana Jones and The Great Circle',
    'The Legend of Zelda: Echoes of Wisdom',
    'Harold Halibut',
    'Dungeons of Hinterberg',
    'Judero',
    'Pacific Drive',],
    bestRPG: [
    'Felvidek',
    'Ys X: Nordics',
    'Metaphor: ReFantazio',
    'Visions of Mana',
    'Like a Dragon: Infinite Wealth',
    'Unicorn Overlord',],
    bestHorror: [
    'Mouthwashing',
    'Crow Country',
    'The Outlast Trials',
    'Withering Rooms',
    'Sorry We\'re Closed',
    'Home Safety Hotline',
    'Still Wakes The Deep',],
    bestPlatformer: [
    'Ultros',
    'UFO 50',
    'Frogun Encore',
    'Leap Year',
    'The Big Catch: Tacklebox',],
    bestFPS: [
    'Bodycam',
    'Mullet MadJack',
    'S.T.A.L.K.E.R. 2',
    'I Am Your Beast',
    'Straftat',],
    bestSimStrategy: [
    'Manor Lords',
    'Tactical Breach Wizards',
    'The Crush House',
    'Core Keeper',],
    bestFighting: [
    'Star Fetchers: Escape from Pork Belly',
    'Dragon Ball: Sparking Zero',
    'Traintracks Combat',
    'TEKKEN 8',],
    bestMultiplayer: [
    'Marvel Rivals',
    'Liar\'s Bar',
    'Dale & Dawson Stationary Supplies ',
    'Lockdown Protocol ',
    'Buckshot Roulette ',
    'Content Warning',
    'The Outlast Trials',
    'Super Mario Party Jamboree',
    'Webfishing',
    'Nightmare Kart',],
    bestRemakeRerelease: [
    'Tomba! Special Edition',
    'Persona 3 Reload',
    'Ghost of Tsushima',
    'Final Fantasy VII Rebirth',
    'Tomb Raider 1-3 Remastered',
    'Silent Hill 2',],
    bestDLC: [
    'Star Fetchers: Escape from Pork Belly',
    'Factorio Space Age',
    'Alan Wake 2: The Lake House',
    'Elden Ring: Shadow of the Erdtree',],
    bestAAA: [
    'Indiana Jones and the Great Circle',
    'Metaphor: ReFantazio',
    'Sonic X Shadow Generations',
    'Star Wars Outlaws',
    'S.T.A.L.K.E.R. 2',
    'TEKKEN 8',],
    bestIndie: [
    'Mouthwashing',
    'Balatro',
    'Dungeons of Hinterberg',
    'Crow Country',
    'Webfishing',
    'Core Keeper',
    'Judero',
    '1000xResist',
    'Reliquia Park',
    'Leap Year',
    'Nightmare Kart',
    'Pacific Drive',
    'I Am Your Beast',
    'Indika',
    'Mullet MadJack',],
    bestDebutIndie: [
    'Sorry We\'re Closed',
    'Balatro',
    'Withering Rooms',
    'Slider',
    'Stig',
    'Webfishing',
    'Pacific Drive',
    '1000xResist ',
    'Judero',],
    mostAnticipated: [
    'Pokemon Legends: ZA',
    'Ghost of Yotei',
    'Silly Polly Beast',
    'The Elder Scrolls VI',
    'Puzzle Depot',
    'You Can\'t See Ghosts',
    'Retchid',
    'Mistified',
    'Deltarune Chapter 3',],
    gameOfTheYear: [
    'Balatro',
    'S.T.A.L.K.E.R. 2',
    'Sonic X Shadow Generations',
    'Leap Year',
    'Judero',
    '1000xResist',
    'Metaphor: ReFantazio',
    'Indiana Jones and the Great Circle']
  };

  return (
    <div className="max-w-5xl mx-auto space-y-6 p-6">
      <div className="space-y-8">
        <DisplayField
          label="Best Solo Developer"          
          values={nominees.bestSoloDev}
        />
        
        <DisplayField
          label="Best Small Developer Team (less than 15)"          
          values={nominees.bestSmallTeam}
        />
        
        <DisplayField
          label="Best New Studio"          
          values={nominees.bestNewStudio}
        />
        
        <DisplayField
          label="Best Early Access"          
          values={nominees.bestEarlyAccess}
        />
        
        <DisplayField
          label="Best Self Published"          
          values={nominees.bestSelfPublished}
        />
        
        <DisplayField
          label="Best Gameplay"          
          values={nominees.bestGameplay}
        />
        
        <DisplayField
          label="Best Narrative"          
          values={nominees.bestNarrative}
        />
        
        <DisplayField
          label="Best Art Direction"          
          values={nominees.bestArtDirection}
        />
        
        <DisplayField
          label="Best Original Score"          
          values={nominees.bestOriginalScore}
        />
        
        <DisplayField
          label="Best Song"          
          values={nominees.bestSong}
        />
        
        <DisplayField
          label="Best Audio Design"          
          values={nominees.bestAudioDesign}
        />
        
        <DisplayField
          label="Best World Building"          
          values={nominees.bestWorldBuilding}
        />
        
        <DisplayField
          label="Best Camera Work"          
          values={nominees.bestCameraWork}
        />
        
        <DisplayField
          label="Best Performance"          
          values={nominees.bestPerformance}
        />
        
        <DisplayField
          label="Most Impactful"          
          values={nominees.mostImpactful}
        />
        
        <DisplayField
          label="Most Innovative"          
          values={nominees.mostInnovative}
        />
        
        <DisplayField
          label="Best Ongoing"          
          values={nominees.bestOngoing}
        />
        
        <DisplayField
          label="Best Community Support"          
          values={nominees.bestCommunitySupport}
        />
        
        <DisplayField
          label="Best Itch.io Game"          
          values={nominees.bestItchioGame}
        />
        
        <DisplayField
          label="Best Action"          
          values={nominees.bestAction}
        />
        
        <DisplayField
          label="Best Adventure"          
          values={nominees.bestAdventure}
        />
        
        <DisplayField
          label="Best RPG"          
          values={nominees.bestRPG}
        />
        
        <DisplayField
          label="Best Horror"          
          values={nominees.bestHorror}
        />
        
        <DisplayField
          label="Best Platformer"          
          values={nominees.bestPlatformer}
        />
        
        <DisplayField
          label="Best FPS"          
          values={nominees.bestFPS}
        />
        
        <DisplayField
          label="Best Sim/Strategy"          
          values={nominees.bestSimStrategy}
        />
        
        <DisplayField
          label="Best Fighting"          
          values={nominees.bestFighting}
        />
        
        <DisplayField
          label="Best Multiplayer"          
          values={nominees.bestMultiplayer}
        />
        
        <DisplayField
          label="Best Remake/Rerelease"          
          values={nominees.bestRemakeRerelease}
        />
        
        <DisplayField
          label="Best DLC"          
          values={nominees.bestDLC}
        />
        
        <DisplayField
          label="Best AAA"          
          values={nominees.bestAAA}
        />
        
        <DisplayField
          label="Best Indie"          
          values={nominees.bestIndie}
        />
        
        <DisplayField
          label="Best Debut Indie"          
          values={nominees.bestDebutIndie}
        />
        
        <DisplayField
          label="Most Anticipated"          
          values={nominees.mostAnticipated}
        />
        
        <DisplayField
          label="Game of the Year"          
          values={nominees.gameOfTheYear}
        />
      </div>
    </div>
  );
};

const DisplayField = ({ label, values }) => (
  <div className="space-y-2">
    <h2 className="block text-2xl font-medium text-[#fff444]">{label}</h2>
    <div className="space-y-2">
      {values?.map((value, index) => (
        <div key={`${label}_${value}_${index}`} className="text-lg font-medium text-white pl-4">
          {value}
        </div>
      ))}
    </div>
  </div>
);